import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import SearchIcon from '@mui/icons-material/Search'
import { Paper, Typography } from '@mui/material'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { TEMPLATE_TYPE } from 'modules/charting/components/template-management/TemplateEditor'
import { FormSchemaControllerService, FormSchemaDTO, FormSchemaLightDTO } from 'services/openapi'
import { setCurrentTemplate, setPreview } from 'store/reducers/charting'
import {
  selectChartingClientIdInContext,
  selectChartingCurrentTemplate,
} from 'store/selectors/charting'
import TEMPLATES from './Templates'
import TemplatesToolbar from './TemplatesToolbar'
import TemplatesToolbarHeader from './TemplatesToolbarHeader'
import ToolbarItem from './TemplatesToolbarItem'
import TemplatesToolbarUserTemplates from './TemplatesToolbarUserTemplates'

const TemplatesToolbarComponent = ({ toolbar }: { toolbar: TemplatesToolbar }) => {
  const dispatch = useDispatch()
  const clientIdInContext = useSelector(selectChartingClientIdInContext)
  const currTemplate = useSelector(selectChartingCurrentTemplate)
  const { t } = useTranslation('common')

  const [searchStr, setSearchStr] = useState('')
  const [errors, setErrors] = useState<string[]>([])
  const [myTemplatePage, setMyTemplatePage] = useState(0)
  const [publicTemplatePage, setPublicTemplatePage] = useState(0)
  const [myTemplateLastPage, setIsMyTemplateLastPage] = useState(false)
  const [publicTemplateLastPage, setIsPublicTemplateLastPage] = useState(false)
  const [myTemplates, setMyTemplates] = useState<FormSchemaLightDTO[]>([])
  const [publicTemplates, setPublicTemplates] = useState<FormSchemaLightDTO[]>([])

  const isTemplateEditor = useMemo(
    () => window.location.pathname.includes('admin/template-editor'),
    []
  )

  const isInClientContext = useMemo(
    () => window.location.pathname.includes('clients/client-record'),
    []
  )

  const mustimuhwTemplates = useMemo(() => {
    if (!isTemplateEditor)
      return Object.values(TEMPLATES).filter(
        (each) =>
          each.name !== TEMPLATES.FormBuilderTemplate.name &&
          each.name !== TEMPLATES.ImageTemplate.name
      )
    return Object.values(TEMPLATES).filter((each) => each.name !== TEMPLATES.ImageTemplate.name)
  }, [isTemplateEditor])

  const handleSelectTemplate = useCallback(
    (key: string) => {
      // enable template editor mode when form builder template is added
      if (key === TEMPLATES.FormBuilderTemplate.name) dispatch(setPreview(false))
      toolbar.handleTemplateMenuItemClick(key)
    },
    [dispatch, toolbar]
  )

  const getFormSchema = useCallback(async (formSchemaId: string) => {
    try {
      setErrors([])
      const resp = await FormSchemaControllerService.getFormSchema(formSchemaId)
      return resp
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrors(
        error?.body?.validationResults?.map((each: { message: string }) => each.message) || []
      )
    }
  }, [])

  const handleSelectUserTemplate = useCallback(
    async (template: FormSchemaDTO) => {
      if (template.id) {
        const formSchemaDTO = await getFormSchema(template.id)
        dispatch(
          setCurrentTemplate({
            ...currTemplate,
            schema: formSchemaDTO?.schema,
            templateType: formSchemaDTO?.templateType,
          })
        )
        toolbar.handleUserTemplateMenuItemClick(
          formSchemaDTO?.schema || '',
          template.id as string,
          formSchemaDTO?.templateType as string
        )
      }
    },
    [currTemplate, dispatch, getFormSchema, toolbar]
  )

  const loadFormSchema = useCallback(
    async (
      formName: string,
      myTemplatePageNum: number,
      isMyTemplateLastPage: boolean,
      publicTemplatePageNum: number,
      isPublicTemplateLastPage: boolean
    ) => {
      try {
        let myTemplateResp
        let myTemplatesList
        let publicTemplateResp
        let publicTemplatesList
        if (!isMyTemplateLastPage) {
          myTemplateResp = await FormSchemaControllerService.searchFormSchema(
            formName,
            undefined,
            true,
            undefined,
            false,
            false,
            isTemplateEditor ? ['PUBLISHED', 'DRAFT'] : ['PUBLISHED'],
            undefined,
            undefined,
            myTemplatePageNum
          )
          setIsMyTemplateLastPage(myTemplateResp.last)
          myTemplatesList =
            myTemplatePageNum === 0
              ? [...(myTemplateResp.content || [])]
              : [...(myTemplates || []), ...(myTemplateResp.content || [])]
          setMyTemplates(myTemplatesList)
        } else {
          myTemplatesList = myTemplates
        }

        if (!isPublicTemplateLastPage) {
          publicTemplateResp = await FormSchemaControllerService.searchFormSchema(
            '%' + formName + '%',
            undefined,
            false,
            true,
            false,
            false,
            ['PUBLISHED'],
            ['PUBLIC'],
            undefined,
            publicTemplatePageNum
          )
          setIsPublicTemplateLastPage(publicTemplateResp.last)
          publicTemplatesList =
            publicTemplatePageNum === 0
              ? [...(publicTemplateResp.content || [])]
              : [...(publicTemplates || []), ...(publicTemplateResp.content || [])]

          setPublicTemplates(
            publicTemplatesList?.filter(
              (publicTemplate) =>
                !myTemplatesList?.find((myTemplate) => myTemplate.id === publicTemplate.id)
            ) || []
          )
        } else {
          publicTemplatesList = publicTemplates
          setPublicTemplates(
            publicTemplatesList?.filter(
              (publicTemplate) =>
                !myTemplatesList?.find((myTemplate) => myTemplate.id === publicTemplate.id)
            ) || []
          )
        }

        setErrors([])
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setErrors(
          error?.body?.validationResults?.map((each: { message: string }) => each.message) || []
        )
      }
    },
    [isTemplateEditor, myTemplates, publicTemplates]
  )

  const handleLoadMoreClick = useCallback(() => {
    if (!myTemplateLastPage) {
      setMyTemplatePage((prev) => prev + 1)
    }
    if (!publicTemplateLastPage) {
      setPublicTemplatePage((prev) => prev + 1)
    }
  }, [myTemplateLastPage, publicTemplateLastPage])

  useEffect(() => {
    loadFormSchema(
      searchStr || '%',
      myTemplatePage,
      myTemplateLastPage,
      publicTemplatePage,
      publicTemplateLastPage
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myTemplatePage, myTemplateLastPage, publicTemplatePage, publicTemplateLastPage])

  useEffect(() => {
    setIsMyTemplateLastPage(false)
    loadFormSchema(searchStr || '%', 0, false, 0, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchStr])

  useEffect(() => {
    if (!myTemplates) loadFormSchema('%', 0, false, 0, false)
  }, [loadFormSchema, myTemplates])

  return (
    <div style={{ height: '100vmax', position: 'absolute' }}>
      <div
        style={{
          alignSelf: 'start',
          height: 'auto',
          position: 'sticky',
          top: 0,
          zIndex: 999,
        }}
      >
        <Paper sx={{ height: '700px', overflowY: 'auto', pb: 2, pl: 2, pr: 4, pt: 2 }}>
          <MISTextField
            InputProps={{ startAdornment: <SearchIcon />, sx: { pl: 1 } }}
            onChange={(e) => setSearchStr(e.target.value)}
            value={searchStr}
          />
          <TemplatesToolbarHeader header={t('charting.templates.mustimuhw-templates')} />
          {mustimuhwTemplates
            .filter(
              (template) => searchStr === '' || t(template.key).toLowerCase().includes(searchStr)
            )
            .map((template) => (
              <ToolbarItem
                disabled={
                  !!(
                    isTemplateEditor &&
                    currTemplate?.templateType &&
                    ((currTemplate.templateType === TEMPLATE_TYPE.AGGREGATE_TEMPLATE &&
                      template.name === TEMPLATES.FormBuilderTemplate.name) ||
                      (currTemplate.templateType === TEMPLATE_TYPE.USER_DEFINED_BASE_TEMPLATE &&
                        template.name !== TEMPLATES.FormBuilderTemplate.name))
                  ) ||
                  (!clientIdInContext &&
                    !isTemplateEditor &&
                    !isInClientContext &&
                    template.name === TEMPLATES.ReferralTemplate.name)
                }
                icon={template.icon}
                itemKey={template.name}
                key={template.id}
                label={t(template.key)}
                onSelect={handleSelectTemplate}
              />
            ))}
          {mustimuhwTemplates.filter(
            (template) => searchStr === '' || t(template.key).toLowerCase().includes(searchStr)
          ).length === 0 && (
            <Typography
              component="span"
              sx={{ color: '#333', fontSize: '10px', fontWeight: 700, pt: 1 }}
            >
              {t('charting.templates.no-templates-available')}
            </Typography>
          )}
          <TemplatesToolbarUserTemplates
            errors={errors}
            header={t('charting.templates.my-templates')}
            onSelect={handleSelectUserTemplate}
            templateType={isTemplateEditor ? currTemplate?.templateType : undefined}
            templates={myTemplates}
          />
          <TemplatesToolbarUserTemplates
            errors={errors}
            header={t('charting.templates.public-templates')}
            onSelect={handleSelectUserTemplate}
            templateType={isTemplateEditor ? currTemplate?.templateType : undefined}
            templates={publicTemplates}
          />
          <MISButton
            onClick={handleLoadMoreClick}
            style={{ display: !myTemplateLastPage || !publicTemplateLastPage ? 'block' : 'none' }}
            variant="text"
          >
            {t('charting.templates.load-more-templates')}
          </MISButton>
        </Paper>
      </div>
    </div>
  )
}

export default TemplatesToolbarComponent
