/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GroupDTO } from '../models/GroupDTO'
import type { PageGroupDTO } from '../models/PageGroupDTO'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class GroupControllerService {
  /**
   * returns group by groupId
   * returns group by groupId
   * @param groupId
   * @returns GroupDTO OK
   * @throws ApiError
   */
  public static getGroup(groupId: string): CancelablePromise<GroupDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/groups/{groupId}',
      path: {
        groupId: groupId,
      },
    })
  }

  /**
   * update group
   * updates group by groupId
   * @param groupId
   * @param requestBody
   * @returns GroupDTO OK
   * @throws ApiError
   */
  public static updateGroup(groupId: string, requestBody: GroupDTO): CancelablePromise<GroupDTO> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/idhe-client-service/v1/groups/{groupId}',
      path: {
        groupId: groupId,
      },
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * Delete group by groupId
   * Soft deletes the group by setting deleted flag to true
   * @param groupId
   * @returns any OK
   * @throws ApiError
   */
  public static deleteGroup(groupId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/idhe-client-service/v1/groups/{groupId}',
      path: {
        groupId: groupId,
      },
    })
  }

  /**
   * Returns a paged list of groups
   * List is returned as Paged collection
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageGroupDTO OK
   * @throws ApiError
   */
  public static getGroups(
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageGroupDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/groups',
      query: {
        page: page,
        size: size,
        sort: sort,
      },
    })
  }

  /**
   * Create group
   * creates group
   * @param requestBody
   * @returns GroupDTO OK
   * @throws ApiError
   */
  public static createGroup(requestBody: GroupDTO): CancelablePromise<GroupDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/idhe-client-service/v1/groups',
      body: requestBody,
      mediaType: 'application/json',
    })
  }

  /**
   * @param name
   * @param groupId
   * @param page Zero-based page index (0..N)
   * @param size The size of the page to be returned
   * @param sort Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
   * @returns PageGroupDTO OK
   * @throws ApiError
   */
  public static searchGroup(
    name: string,
    groupId?: number,
    page?: number,
    size: number = 20,
    sort?: Array<string>
  ): CancelablePromise<PageGroupDTO> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/idhe-client-service/v1/groups/search',
      query: {
        name: name,
        groupId: groupId,
        page: page,
        size: size,
        sort: sort,
      },
    })
  }
}
