import { Component } from 'react'
import axios from 'axios'
import { dateNowIsoString } from 'common/utils/DateUtils'
import AuthService from 'core/helpers/security/AuthService'
import { CodedConceptDto, PersonnelDTO, ProgramTerse } from 'services/openapi'
import ReferralTemplateComponent from './ReferralTemplateComponent'
import {
  ComponentAttachment,
  ETemplateMetadataType,
  ITemplate,
  ITemplateMetadata,
} from '../../blots/TemplateBlot'

export enum EReferralTemplateStateValue {
  Draft = 'Draft',
  Ready = 'Ready',
  Sent = 'Sent',
  Additional_Info_Requested = 'Additional Info Requested',
  Accepted = 'Accepted',
  Booked = 'Booked',
  Reassigned = 'Reassigned',
  Waitlisted = 'Waitlisted',
  Rejected = 'Rejected',
  Cancelled = 'Cancelled',
  Expired = 'Expired',
  Voided = 'Voided',
  Completed = 'Completed',
}

export type TReferralTemplateValue =
  | boolean
  | string
  | CodedConceptDto
  | PersonnelDTO
  | ProgramTerse[]
  | EReferralTemplateStateValue
  | ComponentAttachment[]

export type ReferralTemplateState = {
  clientId?: string
  createdBy?: string
  createdOn?: string
  diagnosis?: CodedConceptDto[]
  history?: string
  lastUpdated?: string
  lastUpdatedBy?: string
  phiWithheld?: boolean
  reason?: string
  referredBy?: string
  referredTo?: string
  requestedConsultationComment?: string
  requestedConsultationFirstAvailable?: boolean
  requestedTests?: string
  state?: EReferralTemplateStateValue
  sentBy?: string
  appointmentBooked?: boolean
  appointmentDate?: string
  appointmentTime?: string
  appointmentLocation?: string
  attachments?: ComponentAttachment[]
}

export type ReferralTemplateProps = { data: ReferralTemplateState }

class ReferralTemplate
  extends Component<ReferralTemplateProps, ReferralTemplateState>
  implements ITemplate
{
  type = 'ReferralTemplate'
  state: ReferralTemplateState
  attachments: ComponentAttachment[] = []

  constructor(props: ReferralTemplateProps) {
    super(props)
    this.state = { ...props.data }
  }

  static getMetadata = (): ITemplateMetadata => ({
    appointmentBooked: { label: 'Date', type: ETemplateMetadataType.boolean },
    appointmentDate: { label: 'Date', type: ETemplateMetadataType.Date },
    appointmentLocation: { label: 'Location', type: ETemplateMetadataType.string },
    appointmentTime: { label: 'Time', type: ETemplateMetadataType.string },
    attachments: {
      isArray: true,
      label: 'Attachments',
      type: ETemplateMetadataType.ComponentAttachment,
    },
    createdBy: { label: 'Created by', type: ETemplateMetadataType.ProviderId },
    createdOn: { label: 'Created on', type: ETemplateMetadataType.DateTime },
    diagnosis: { isArray: true, label: 'Diagnosis', type: ETemplateMetadataType.CodedConceptDto },
    history: { label: 'History', type: ETemplateMetadataType.string },
    lastUpdated: { label: 'Last updated on', type: ETemplateMetadataType.DateTime },
    lastUpdatedBy: { label: 'Last updated by', type: ETemplateMetadataType.ProviderId },
    phiWithheld: { label: 'PHI Withheld', type: ETemplateMetadataType.boolean },
    reason: { label: 'Reason for Referral', required: true, type: ETemplateMetadataType.string },
    referredBy: { label: 'Referred by', required: true, type: ETemplateMetadataType.ProviderId },
    referredTo: { label: 'Referred to', required: true, type: ETemplateMetadataType.ProviderId },
    requestedConsultationComment: {
      label: 'Requested Consultations Comment',
      type: ETemplateMetadataType.string,
    },
    requestedConsultationFirstAvailable: {
      label: 'Requested Consultations First Available',
      type: ETemplateMetadataType.boolean,
    },
    requestedTests: {
      label: 'Requested Tests or Services',
      type: ETemplateMetadataType.string,
    },
    sentBy: { label: 'Sent by', required: true, type: ETemplateMetadataType.ProviderId },
    state: { label: 'Referral Status', type: ETemplateMetadataType.string },
  })

  static getTemplateDisplayName = (): string => 'Referral Template'

  static getTemplateVersion = (): number => 1

  getData = () => ({
    appointmentBooked: this.state.appointmentBooked,
    appointmentDate: this.state.appointmentDate,
    appointmentLocation: this.state.appointmentLocation,
    appointmentTime: this.state.appointmentTime,
    attachments: this.state.attachments,
    clientId: this.state.clientId,
    createdBy: this.state.createdBy,
    createdOn: this.state.createdOn,
    diagnosis: this.state.diagnosis,
    history: this.state.history,
    lastUpdated: this.state.lastUpdated,
    lastUpdatedBy: this.state.lastUpdatedBy,
    phiWithheld: this.state.phiWithheld,
    reason: this.state.reason,
    referredBy: this.state.referredBy,
    referredTo: this.state.referredTo,
    requestedConsultationComment: this.state.requestedConsultationComment,
    requestedConsultationFirstAvailable: this.state.requestedConsultationFirstAvailable,
    requestedTests: this.state.requestedTests,
    sentBy: this.state.sentBy,
    state: this.state.state,
  })

  handleChange = (key: string, value: TReferralTemplateValue, lastUpdatedBy?: string) => {
    if (key === 'addAttachments') {
      this.setState((prevState) => ({
        ...prevState,
        attachments: [...(prevState.attachments || []), ...(value as ComponentAttachment[])],
        lastUpdated: dateNowIsoString(),
        lastUpdatedBy,
      }))
    } else {
      this.setState({
        ...this.state,
        [key]: value,
        lastUpdated: dateNowIsoString(),
        lastUpdatedBy,
      })
    }
  }

  handleFileUpload = async (files: ComponentAttachment[]) => {
    if (files && files.length > 0) {
      try {
        const newAttachments = files.filter((attachment) => !attachment.fileId)
        const oldAttachments = files.filter((attachment) => attachment.fileId)
        if (newAttachments.length !== 0) {
          const documentDTOs = newAttachments.map((attachment) => ({
            associatedEntities: [
              { entityId: this.state.clientId, entityType: 'CLIENT_CHARTING_ENTRY_REFERRAL' },
            ],
            ontologyTitle: { code: '74155-3', codeSystemOid: '2.16.840.1.113883.6.1' },
          }))
          const formData = new FormData()

          newAttachments.forEach((attachment) => {
            if (attachment.file) {
              formData.append('files', attachment.file)
            }
          })

          formData.append('requestBodyJson', JSON.stringify(documentDTOs))
          const token = AuthService.getAccessToken() || ''
          const result = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/idhe-document-management-service/v1/documents`,
            formData,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'multipart/form-data',
              },
            }
          )

          this.attachments = []
          result.data.forEach((document) => {
            this.attachments.push({
              fileId: document.id,
              fileSize: document.fileSize,
              name: document.fileName,
            })
          })
          this.attachments.push(...oldAttachments)
          await new Promise<void>((resolve) => {
            this.setState({ ...this.state, attachments: this.attachments || undefined }, resolve)
          })
        }
      } catch (error) {
        return Promise.reject(error)
      }
    }
  }

  save = async () => {
    if (this.state.attachments) {
      try {
        await this.handleFileUpload(this.state.attachments)
      } catch (error) {
        return Promise.reject(error)
      }
    }
    return Promise.resolve()
  }

  cancel = async () => {
    console.log('Cancel- Referral')
  }

  render() {
    return <ReferralTemplateComponent {...this.state} onChange={this.handleChange} />
  }
}

export default ReferralTemplate
