import './Editor.scss'

import {
  forwardRef,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { FilePond } from 'react-filepond'
import { useTranslation } from 'react-i18next'
import ReactQuill, { ReactQuillProps } from 'react-quill'
import { useDispatch, useSelector } from 'react-redux'
import 'quill-mention'
import { Box, FormControlLabel, Grid, Stack, Switch } from '@mui/material'
import debounce from 'lodash/debounce'
import { DeltaStatic } from 'quill'
import { useRecoilValue } from 'recoil'
import uploadIcon from 'assets/images/upload_icon.svg'
import { isoDateToDisplayFormat } from 'common/utils/DateUtils'
import {
  getClientAgeString,
  getClientPreferredName,
  getIdentifierValue,
} from 'modules/shared/clientUtils'
import { terminologySelector } from 'recoil/terminology'
import {
  ClientControllerService,
  ClientDTO,
  EncounterServiceTemplateDTO,
  ProgramTerse,
} from 'services/openapi'
import {
  MEMBER_IDENTIFIER_TYPE_VOCAB_NAME,
  MIS_GENDER_VOCAB_NAME,
} from 'services/terminologyConstants'
import { setClientIdInContext, setPreview } from 'store/reducers/charting'
import { selectChartingPreviewState } from 'store/selectors/charting'
import { selectEditorDragState } from 'store/selectors/editorDrag'
import { TValue } from '../../Charting'
import DocumentList from '../document/DocumentList'
import MentionNode from '../MentionItem'
import '../modules/registration'
import TEMPLATES from '../modules/TemplatesToolbar/Templates'

export enum EMentionTypes {
  Client = 'Client',
}

export interface NavigationDetail {
  activeMenuItem?: string
  fromMenuItem?: string
}

const mentionValues: TValue[] = [{ id: 1, value: 'Client' }]

interface EditorProps extends ReactQuillProps {
  disableMentions?: boolean
  onMentionResultSelect?: (
    item: ClientDTO | ProgramTerse | EncounterServiceTemplateDTO,
    type: EMentionTypes
  ) => void
}
const Editor = forwardRef((props: EditorProps, ref) => {
  const dispatch = useDispatch()
  const preview = useSelector(selectChartingPreviewState)
  const editorDrag = useSelector(selectEditorDragState)
  const { t } = useTranslation('common')

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [attachments, setAttachments] = useState<any[]>([])
  const DRAG_OVER_TIMEOUT = 1000
  const [showFileDropZone, setShowFileDropZone] = useState<boolean>(false)
  // The timeout is needed to offset the showing of the file upload component which
  // calls onDragLeave and onDragEnter repeatedly which shows the file upload component
  // flickering.
  const dragOverTimeout = useRef<number>(0)

  const editorRef = useRef<ReactQuill | null>(null)
  const mentionTypeRef = useRef<EMentionTypes | null>(null)
  const lastMentionTypeRef = useRef<EMentionTypes | null>(null)
  const mentionResultsRef = useRef<ClientDTO[] | ProgramTerse[] | EncounterServiceTemplateDTO[]>([])
  const serviceDateTimeRef = useRef<string | undefined>()
  const navigationMenuDetailsRef = useRef<NavigationDetail | undefined>()
  const programsRef = useRef<TValue[]>([])
  const servicesRef = useRef<TValue[]>([])
  const clientMentionsRef = useRef<string[] | undefined>()
  const clientIdContextRef = useRef<string | undefined>()
  const genderOptions = useRecoilValue(terminologySelector(MIS_GENDER_VOCAB_NAME))
  const identifierTypeOptions = useRecoilValue(
    terminologySelector(MEMBER_IDENTIFIER_TYPE_VOCAB_NAME)
  )

  const isTemplateEditor = useMemo(
    () => window.location.pathname.includes('admin/template-editor'),
    []
  )

  const handleMenuItemOnClose = useCallback(() => {
    const editor = editorRef.current?.getEditor()
    if (editor) mentionTypeRef.current = null
  }, [])

  const handleMentionItemOnSelect = useCallback(
    (item: TValue, insertItem: (item: TValue) => void) => {
      const editor = editorRef.current?.getEditor()
      if (editor) {
        if (mentionTypeRef.current) {
          item.type = lastMentionTypeRef.current || ''
          insertItem(item)
          switch (lastMentionTypeRef.current) {
            case EMentionTypes.Client:
            default:
              props.onMentionResultSelect?.(
                (mentionResultsRef.current as ClientDTO[]).find(
                  (each) => each.id === item.id
                ) as ClientDTO,
                EMentionTypes.Client
              )
              break
          }
          mentionTypeRef.current = null
        } else {
          const mentionModule = editor.getModule('mention')
          editor.deleteText(
            mentionModule.mentionCharPos,
            mentionModule.cursorPos - mentionModule.mentionCharPos,
            'user'
          )
          mentionTypeRef.current = item.value as EMentionTypes
          setTimeout(() => mentionModule.openMenu('@'), 100)
        }
      }
    },
    [props]
  )

  const getClientDetail = useCallback(
    (client: ClientDTO) => {
      const preferredName = getClientPreferredName(client)
      const gender =
        client.gender && genderOptions?.find((option) => option.code === client.gender?.code)?.name
      const birthDate = client.birthdate ? isoDateToDisplayFormat(client.birthdate) : ''
      const age = client.birthdate ? getClientAgeString(client.birthdate) : ''
      const statusCardNumber =
        getIdentifierValue('Indian Registry Status Number', client, identifierTypeOptions) || ''
      return `${preferredName}|${t('client-header.gender-label')}: ${gender}| ${t(
        'client-header.birthdate-label'
      )}:${birthDate}|${t('client-header.age-label')}: ${age}|${t(
        'client-header.status-card-label'
      )}:${statusCardNumber} `
    },
    [genderOptions, identifierTypeOptions, t]
  )

  const handleSource = useCallback(
    async (
      searchTerm: string,
      renderList: (values: TValue[], searchTerm: string) => ReactNode,
      mentionChar: string
    ) => {
      let values: TValue[] = []
      if (mentionChar === '\\') {
        values = mentionValues
        if (searchTerm.length === 0) {
          renderList(values, searchTerm)
        } else {
          const matches: TValue[] = []
          for (let i = 0; i < values.length; i++)
            if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
              matches.push(values[i])
          renderList(matches, searchTerm)
        }
      } else if (mentionTypeRef.current && mentionChar === '@') {
        if (searchTerm.length < 4) {
          values.push({
            disabled: true,
            id: 1,
            value: `Search ${mentionTypeRef.current.toLocaleLowerCase()} by name`,
          })
        } else {
          let resp
          lastMentionTypeRef.current = mentionTypeRef.current
          switch (mentionTypeRef.current) {
            case EMentionTypes.Client:
            default:
              resp = await ClientControllerService.searchClient(searchTerm, '', '', '', -1)
              mentionResultsRef.current = resp.content || []
              values =
                resp.content && resp.content.length > 0
                  ? resp.content.map((each) => {
                      return { id: each.id as string, value: getClientDetail(each) }
                    })
                  : [
                      {
                        disabled: true,
                        id: '1',
                        value: 'No client found',
                      },
                    ]
              break
          }
        }
        renderList(values, searchTerm)
      } else {
        renderList([], searchTerm)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleRedo = useCallback(() => {
    const editor = editorRef.current?.getEditor()
    if (editor) (editor as unknown as { history: { redo: () => void } }).history.redo()
  }, [])

  const handleUndo = useCallback(() => {
    const editor = editorRef.current?.getEditor()
    if (editor) (editor as unknown as { history: { undo: () => void } }).history.undo()
  }, [])

  const addFile = useCallback(
    async (error, file) => {
      if (editorDrag) {
        if (file.fileType.match(/(image\/.*)/gi)) {
          try {
            const template = TEMPLATES.ImageMarkerTemplate
            const editor = editorRef.current?.getEditor()
            if (template && editor) {
              const range = editor.getSelection(true)
              editor.insertText(range.index, '\n', 'user')
              editor.insertEmbed(
                range.index + 1,
                'template-blot',
                {
                  templateData: { file: file.file },
                  templateId: template.id,
                  templateName: template.name,
                },
                'user'
              )
              editor.insertText(range.index + 2, '\n', 'user')
              editor.setSelection({ index: range.index + 3, length: 0 }, 'user')
            }
          } catch (e) {
            console.error(e)
          }

          setShowFileDropZone(false)
        } else {
          setAttachments((prev) => [...prev, file])
        }
      }
    },
    [editorDrag]
  )

  const onDragEnter = (event) => {
    if (!isTemplateEditor) {
      event.preventDefault()
      setShowFileDropZone(true)
      dragOverTimeout.current = DRAG_OVER_TIMEOUT
      setTimeout(() => (dragOverTimeout.current = 0))
    }
  }

  const onDragLeave = (event) => {
    if (!isTemplateEditor) {
      event.preventDefault()
      if (dragOverTimeout.current > 0) return
      setShowFileDropZone(false)
    }
  }

  const modules = useMemo(() => {
    const mention = props.disableMentions
      ? undefined
      : {
          allowedChars: /^[a-zA-Z0-9\s:]*$/,
          mentionDenotationChars: ['\\', '@'],
          onClose: handleMenuItemOnClose,
          onSelect: handleMentionItemOnSelect,
          renderItem: (item: TValue) => MentionNode(item),
          renderLoading: () => MentionNode({ id: 1, value: 'Loading...' }),
          showDenotationChar: false,
          source: debounce(handleSource, 500),
        }

    return {
      mention,
      templatesToolbar: {
        container: '#templates-toolbar',
      },
      toolbar: {
        container: [
          ['undo', 'redo'],
          [{ font: [] }, { size: [] }],
          ['bold', 'italic', 'underline', 'strike'],
          [{ color: [] }, { background: [] }],
          [{ script: 'super' }, { script: 'sub' }],
          [{ header: '1' }, { header: '2' }, 'blockquote', 'code-block'],
          [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
          [{ align: [] }],
          ['link', 'image'],
          ['clean'],
        ],
        handlers: {
          redo: handleRedo,
          undo: handleUndo,
        },
      },
    }
  }, [
    props.disableMentions,
    handleMentionItemOnSelect,
    handleMenuItemOnClose,
    handleRedo,
    handleSource,
    handleUndo,
  ])

  useImperativeHandle(
    ref,
    () => ({
      getAttachments() {
        return attachments
      },
      getClientIdContext() {
        return clientIdContextRef.current
      },
      getClientMentions() {
        return clientMentionsRef.current
      },
      getContents() {
        const editor = editorRef.current?.getEditor()
        if (editor) return editor.getContents()
      },
      getNavigationDetails() {
        return navigationMenuDetailsRef.current
      },

      getPrograms() {
        return programsRef.current
      },
      getServices() {
        return servicesRef.current
      },
      setAttachments(attachments) {
        setAttachments(attachments)
      },
      setClientIdContext(clientId: string | undefined) {
        clientIdContextRef.current = clientId
        dispatch(setClientIdInContext(clientId))
      },
      setClientMentions(clientMentions: string[] | undefined) {
        clientMentionsRef.current = clientMentions
      },
      setContents(contents: string | DeltaStatic) {
        const editor = editorRef.current?.getEditor()
        if (editor) {
          if (typeof contents === 'string') editor.setText(contents, 'user')
          else setTimeout(() => editor.setContents(contents, 'user'), 10)
        }
      },
      setNavigationDetails(navigationDetail: NavigationDetail | undefined) {
        navigationMenuDetailsRef.current = navigationDetail
      },
      setPrograms(programs: TValue[] | []) {
        programsRef.current = programs
      },
      setServiceDateTime(serviceDateTime: string | undefined) {
        serviceDateTimeRef.current = serviceDateTime
      },
      setServices(services: TValue[] | []) {
        servicesRef.current = services
      },
    }),
    [attachments, dispatch]
  )

  useEffect(() => {
    dispatch(setPreview(!isTemplateEditor))
  }, [dispatch, isTemplateEditor])

  useEffect(() => {
    setShowFileDropZone(false)
  }, [attachments])

  return (
    <Grid container spacing={2}>
      <Grid className="charting-entry-section" item xs={10}>
        {isTemplateEditor && (
          <Box
            alignContent="end"
            sx={{
              border: '1px solid #ccc',
              borderBottom: 'none',
              borderRadius: '4px 4px 0 0',
              height: '38px',
            }}
          >
            <Stack direction="row-reverse" spacing={3} sx={{ pb: 1, pt: 1 }}>
              <FormControlLabel
                control={
                  <Switch
                    checked={preview}
                    name="template-preview-mode"
                    onChange={(_, checked) => dispatch(setPreview(checked))}
                    size="small"
                  />
                }
                label={t('charting.canvas.template-preview-mode')}
                sx={{ display: 'flex', pr: 1 }}
              />
            </Stack>
          </Box>
        )}
        {attachments && attachments.length > 0 && (
          <Grid item sx={{ paddingBottom: 2, width: '100%' }} xs={12}>
            <DocumentList
              files={attachments.map((file) => ({
                fileId: file.fileId || undefined,
                fileSize: file.fileSize,
                name: file.name || file.filename,
              }))}
              onDelete={(name) => {
                setAttachments((prev) =>
                  prev.filter((file) => file.name !== name && file.filename !== name)
                )
              }}
            />
          </Grid>
        )}
        <Grid
          item
          onDragEnter={onDragEnter}
          onDragLeave={onDragLeave}
          onDrop={onDragLeave}
          sx={{ position: 'relative' }}
          xs={12}
        >
          <ReactQuill className="canvas" modules={modules} ref={editorRef} {...props} />
          {!isTemplateEditor && (
            <div
              style={{
                height: '100%',
                left: 0,
                position: 'absolute',
                top: 0,
                visibility: showFileDropZone && editorDrag ? 'visible' : 'hidden',
                width: '100%',
                zIndex: 10,
              }}
            >
              <FilePond
                allowMultiple
                className="dropzone"
                labelIdle={`<img src="${uploadIcon}"></img></br>Drop files here or <span style="color: orange">browse</span>`}
                maxFiles={6}
                onaddfile={addFile}
              />
            </div>
          )}
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <Box id="templates-toolbar" sx={{ pb: 2, width: '100%' }} />
      </Grid>
    </Grid>
  )
})
Editor.displayName = 'Editor'

export default Editor
