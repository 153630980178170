import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface EditorDragState {
  isDragged: boolean
}

const initialState: EditorDragState = {
  isDragged: true,
}

export const editorDragSlice = createSlice({
  initialState,
  name: 'editorDrag',
  reducers: {
    setEditorDrag: (state, action: PayloadAction<boolean>) => {
      state.isDragged = action.payload
    },
  },
})

export const { setEditorDrag } = editorDragSlice.actions
export default editorDragSlice.reducer
