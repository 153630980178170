import { Provider } from 'react-redux'
import Quill, { DeltaOperation, DeltaStatic } from 'quill'
import { createRoot, Root } from 'react-dom/client'
import { store } from 'store/index'
import TEMPLATES from './Templates'
import TemplatesToolbarComponent from './TemplatesToolbarComponent'
import { TTemplateData } from '../../blots/TemplateBlot'
import { TEMPLATE_TYPE } from '../../template-management/TemplateEditor'

class TemplatesToolbar {
  quill: Quill
  options: { container: string }
  container: Element | null

  constructor(quill: Quill, options: { container: string }) {
    this.quill = quill
    this.options = options
    this.container = document.querySelector(options.container)

    let rootNode: Root
    if (this.quill && this.container) {
      rootNode = createRoot(this.container)
      rootNode.render(
        <Provider store={store}>
          <TemplatesToolbarComponent toolbar={this} />
        </Provider>
      )
    }
  }

  handleUserTemplateMenuItemClick(content: string, id?: string, templateType?: string) {
    const delta = JSON.parse(content) as DeltaStatic
    const range = this.quill.getSelection(true)
    this.quill.insertText(range.index, '\n', 'user')
    let count = 1
    for (const op of delta.ops as DeltaOperation[]) {
      if (op.insert['template-blot']) {
        if (templateType !== TEMPLATE_TYPE.AGGREGATE_TEMPLATE) {
          op.insert['template-blot'].templateId = id
        }
        this.quill.insertEmbed(
          range.index + count,
          'template-blot',
          op.insert['template-blot'],
          'user'
        )
        count += 1
      } else {
        this.quill.insertText(range.index + count, op.insert, 'user')
        count += op.insert.length
      }
      this.quill.setSelection({ index: range.index + count, length: 0 }, 'user')
    }
  }

  handleTemplateMenuItemClick(templateName: string, templateData?: TTemplateData) {
    const template = Object.values(TEMPLATES).find((each) => each.name === templateName)?.template
    if (template) {
      const range = this.quill.getSelection(true)
      this.quill.insertText(range.index, '\n', 'user')
      this.quill.insertEmbed(
        range.index + 1,
        'template-blot',
        {
          templateData,
          templateId: Object.values(TEMPLATES).find((each) => each.name === templateName)?.id,
          templateName,
        },
        'user'
      )
      this.quill.insertText(range.index + 2, '\n', 'user')
      this.quill.setSelection({ index: range.index + 3, length: 0 }, 'user')
    }
  }
}

export default TemplatesToolbar
