import { SyntheticEvent, useEffect, useState } from 'react'
import { Autocomplete, FormControl, Typography } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { v4 as uuidv4 } from 'uuid'
import misTheme from 'core/styles/theme'
import MISChip from './MISChip'
import MISTextField from './MISTextField'

export type MultiValueOption = {
  label: string
  value: string
}

type MultiValueAutocompleteCustomProps = {
  label: string | React.ReactNode
  required?: boolean
  maxLength?: number
  error?: boolean
  helperText?: string
  defaultValue?: MultiValueOption[]
  options: MultiValueOption[]
  value: MultiValueOption[]
  onChange: (event: SyntheticEvent, newValue: MultiValueOption[]) => void
  allowFreeText?: boolean
  placeholder?: string
  shrinkLabel?: boolean
}

const MISMultiValueAutocomplete = ({
  allowFreeText = false,
  defaultValue = [{ label: '', value: '' }],
  error,
  helperText,
  label,
  onChange,
  options,
  placeholder,
  required = false,
  shrinkLabel = false,
  value = [{ label: '', value: '' }],
}: MultiValueAutocompleteCustomProps) => {
  const [inputValue, setInputValue] = useState('')
  const [filteredOptions, setFilteredOptions] = useState<MultiValueOption[]>([])

  useEffect(() => {
    setFilteredOptions(
      options.filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()))
    )
  }, [inputValue, options])

  const renderOption1 = (props, option) => {
    const matches = option.label.toLowerCase().indexOf(inputValue.toLowerCase())
    const beforeMatch = option.label.substring(0, matches)
    const matchText = option.label.substring(matches, matches + inputValue.length)
    const afterMatch = option.label.substring(matches + inputValue.length)

    return (
      <li {...props} key={uuidv4()}>
        <Typography sx={{ padding: '7px' }}>
          {beforeMatch}
          <strong>{matchText}</strong>
          {afterMatch}
        </Typography>
      </li>
    )
  }

  const handleChange = (event: SyntheticEvent, values: any) => {
    if (Array.isArray(values)) {
      const updatedValues = values.map((v) => {
        if (v.label) {
          return v
        } else {
          return { label: v, value: v }
        }
      })
      onChange(event, updatedValues)
    }
  }
  return (
    <ThemeProvider theme={misTheme}>
      <FormControl size="small">
        <Autocomplete
          defaultValue={defaultValue}
          filterSelectedOptions
          freeSolo={allowFreeText}
          id="tags-filled"
          isOptionEqualToValue={(option, value) => option.value === value.value}
          multiple
          onChange={(event, value) => handleChange(event, value)}
          onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
          options={filteredOptions}
          renderInput={(params) => (
            <MISTextField
              {...params}
              InputLabelProps={shrinkLabel ? { shrink: shrinkLabel } : {}}
              error={error}
              helperText={helperText}
              label={label}
              placeholder={placeholder}
              required={required}
            />
          )}
          renderOption={renderOption1}
          renderTags={(value: readonly MultiValueOption[], getTagProps) =>
            value.map((option: MultiValueOption, index: number) => (
              <MISChip
                {...getTagProps({ index })}
                key={index}
                label={option.label}
                size="small"
                variant="outlined"
              />
            ))
          }
          value={value}
        />
      </FormControl>
    </ThemeProvider>
  )
}

export default MISMultiValueAutocomplete
