import { User } from 'oidc-client-ts'

class AuthService {
  private user: User | null = null

  setUser(user: User) {
    this.user = user
  }

  getUser() {
    return this.user
  }

  getAccessToken() {
    return this.user?.access_token || null
  }

  getIdToken() {
    return this.user?.id_token || null
  }
}

export default new AuthService()
