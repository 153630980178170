import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { FilePond, registerPlugin } from 'react-filepond'
import { useTranslation } from 'react-i18next'
import { AuthContextProps, useAuth } from 'react-oidc-context'
import CloseIcon from '@mui/icons-material/Close'
import {
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import axios from 'axios'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'
import { useRecoilState, useRecoilValue } from 'recoil'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISMultiValueAutocomplete, {
  MultiValueOption,
} from 'common/components/form/MISMultiValueAutocomplete'
import MISTextField from 'common/components/form/MISTextField'
import MISButton from 'common/components/MISButton'
import { useSnack } from 'common/components/snackbar/useSnack'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css'
import { govAgenciesWithDomainsAtom } from 'recoil/atoms'
import { isDirtyState } from 'recoil/isDirty'
import { terminologySelector } from 'recoil/terminology'
import {
  CodedConceptDto,
  CodedRef,
  DocumentControllerService,
  DocumentDTO,
  GovernanceAgencyWithDomainsDTO,
} from 'services/openapi'
import {
  MIS_DOC_SUBJECT_MATTER,
  MIS_DOCUMENT_ROLES,
  MIS_DOCUMENT_SETTING,
  MIS_DOCUMENT_TITLE,
  MIS_KIND_OF_DOC,
  MIS_SERVICE_TYPES_FOR_DOCUMENT,
} from 'services/terminologyConstants'
import { getFormattedOptions } from '../utils'
import '../../charting/components/editor/Editor.scss'
registerPlugin(FilePondPluginImagePreview)

type OrgOptionType = {
  label: string | undefined
  id?: string | undefined
  value: GovernanceAgencyWithDomainsDTO
}

type FormattedCodedConcept = {
  label: string
  value: CodedConceptDto
}

type EditData = {
  document?: DocumentDTO
}

export type AddEditDocumentDialogProps = {
  entityId: string
  entityType: string
  editData?: EditData
  openDialog: boolean
  onCloseCallback: (update: boolean) => void
  tags?: string[]
}

const AddEditDocumentDialog = ({
  editData,
  entityId,
  entityType,
  onCloseCallback,
  openDialog,
  tags,
}: AddEditDocumentDialogProps) => {
  const { t } = useTranslation('common')
  const { handleApiError } = useErrorHandler()
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const auth: AuthContextProps = useAuth()

  const [files, setFiles] = useState<any>([])
  const [currentDocumentData, setCurrentDocumentData] = useState<DocumentDTO>(
    editData?.document || {}
  )

  const kindOfDocumentOptions: CodedConceptDto[] = useRecoilValue(
    terminologySelector(MIS_KIND_OF_DOC)
  )
  const typeOfServiceOptions: CodedConceptDto[] = useRecoilValue(
    terminologySelector(MIS_SERVICE_TYPES_FOR_DOCUMENT)
  )
  const subjectMatterOptions: CodedConceptDto[] = useRecoilValue(
    terminologySelector(MIS_DOC_SUBJECT_MATTER)
  )
  const settingOptions: CodedConceptDto[] = useRecoilValue(
    terminologySelector(MIS_DOCUMENT_SETTING)
  )
  const roleOptions: CodedConceptDto[] = useRecoilValue(terminologySelector(MIS_DOCUMENT_ROLES))
  const titleOptions: CodedConceptDto[] = useRecoilValue(terminologySelector(MIS_DOCUMENT_TITLE))
  const { showSnackSuccess } = useSnack()

  const orgOptions = useRecoilValue(govAgenciesWithDomainsAtom)
    .filter((ga) => ga.governanceAgencyType?.some((x) => x.code !== 'ReportingCommunity'))
    .map((x) => ({ label: x.name, value: x }))

  const handleClose = useCallback(
    (update: boolean) => {
      onCloseCallback(update)
    },
    [onCloseCallback]
  )

  useEffect(() => {
    const handleIfEdit = () => {
      if (editData?.document) {
        setCurrentDocumentData(editData.document)
      }
    }
    handleIfEdit()
  }, [editData])

  const handleSaveClicked = useCallback(async () => {
    if (files && files.length > 0) {
      files.forEach(async (file: any) => {
        if (!editData) {
          let data = currentDocumentData
          data = { ...data, associatedEntities: [{ entityId: entityId, entityType: entityType }] }
          const formData = new FormData()

          formData.append('files', file)

          formData.append('requestBodyJson', JSON.stringify([data]))

          await axios
            .post(
              `${process.env.REACT_APP_API_URL}/api/idhe-document-management-service/v1/documents`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${auth.user?.access_token}`,
                  'Content-Type': 'multipart/form-data',
                },
              }
            )
            .then((result) => {
              showSnackSuccess('Upload of ' + file.file.name + ' successful')
              handleClose(true)
            })
            .catch((error) => {
              handleApiError(error)
              handleClose(false)
            })
        } else if (editData.document?.id) {
          let data = currentDocumentData
          data = { ...data, associatedEntities: [{ entityId: entityId, entityType: entityType }] }
          DocumentControllerService.updateDocument(editData.document.id, JSON.stringify(data), {
            file: file.file,
          })
            .then((result) => {
              showSnackSuccess('Re-Upload of ' + file.file.name + ' successful')
              handleClose(true)
            })
            .catch((error) => {
              handleApiError(error)
              handleClose(false)
            })
        }
      })
    } else if (editData && editData.document?.id) {
      let data = currentDocumentData
      data = { ...data, associatedEntities: [{ entityId: entityId, entityType: entityType }] }
      DocumentControllerService.updateDocument(editData.document?.id, JSON.stringify(data))
        .then((result) => {
          showSnackSuccess(t('documents.successful-update-message'))
          handleClose(true)
        })
        .catch((error) => {
          handleApiError(error)
          handleClose(false)
        })
    } else {
      handleClose(false)
    }
  }, [
    files,
    editData,
    currentDocumentData,
    entityId,
    entityType,
    auth.user?.access_token,
    showSnackSuccess,
    handleClose,
    handleApiError,
    t,
  ])

  const onChangeRow = useCallback(
    (
      field: string,
      value: string | string[] | number | CodedRef | ChangeEvent<HTMLInputElement> | CodedConceptDto
    ) => {
      if (!isDirty) setIsDirty(true)

      let data: DocumentDTO = { ...currentDocumentData }

      switch (field) {
        case 'description':
          data = { ...data, description: value as string }
          break
        case 'keywords': {
          data = { ...data, tags: value as string }
          break
        }
        case 'ontologyKind': {
          const refValue = value as FormattedCodedConcept
          data = {
            ...data,
            ontologyKind: {
              code: refValue.value.code,
              codeSystemOid: refValue.value.codeSystemOid,
            },
          }
          break
        }
        case 'ontologyType': {
          const refValue = value as FormattedCodedConcept
          data = {
            ...data,
            ontologyType: {
              code: refValue.value.code,
              codeSystemOid: refValue.value.codeSystemOid,
            },
          }
          break
        }
        case 'ontologySubject': {
          const refValue = value as FormattedCodedConcept
          data = {
            ...data,
            ontologySubject: {
              code: refValue.value.code,
              codeSystemOid: refValue.value.codeSystemOid,
            },
          }
          break
        }
        case 'ontologyRole': {
          const refValue = value as FormattedCodedConcept
          data = {
            ...data,
            ontologyRole: {
              code: refValue.value.code,
              codeSystemOid: refValue.value.codeSystemOid,
            },
          }
          break
        }
        case 'ontologySetting': {
          const refValue = value as FormattedCodedConcept
          data = {
            ...data,
            ontologySetting: {
              code: refValue.value.code,
              codeSystemOid: refValue.value.codeSystemOid,
            },
          }
          break
        }
        case 'ontologyTitle': {
          const refValue = value as CodedConceptDto
          data = {
            ...data,
            ontologyTitle: { code: refValue.code, codeSystemOid: refValue.codeSystemOid },
          }
          break
        }
        case 'author':
          data = { ...data, author: value as string }
          break
        case 'authoredDatetime':
          data = { ...data, authoredDatetime: value as string }
          break
        case 'authoringOrgId': {
          const val = value as OrgOptionType
          data = { ...data, authoringOrgId: val?.value.id as string }
          break
        }
        default:
          break
      }

      setCurrentDocumentData(data as DocumentDTO)
    },
    [currentDocumentData, isDirty, setIsDirty]
  )

  return (
    <div>
      <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={openDialog}>
        <IconButton
          aria-label="close"
          onClick={() => handleClose(false)}
          sx={{
            color: (theme) => theme.palette.grey[500],
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FilePond
                allowMultiple
                files={files}
                labelIdle='<img src="https://files.axshare.com/gsc/K2WVWN/79/b6/d4/79b6d4ac7dde436dbde46026487b381c/images/documents/u148.svg?pageId=f83f9ca4-a4d9-42e0-82b8-341d8c4985c1"></img></br>Drop files here or <span style="color: orange">browse</span>'
                maxFiles={5}
                onupdatefiles={setFiles}
              />
            </Grid>
            {editData && (
              <Grid item xs={12}>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t('documents.added-by')}</TableCell>
                        <TableCell align="right">{t('documents.file-name')}</TableCell>
                        <TableCell align="right">{t('documents.added-on')}</TableCell>
                        <TableCell align="right">{t('documents.file-size')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        key={editData.document?.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          {editData.document?.author}
                        </TableCell>
                        <TableCell align="right">{editData.document?.fileName}</TableCell>
                        <TableCell align="right">{editData.document?.authoredDatetime}</TableCell>
                        <TableCell align="right">{editData.document?.fileSize}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <MISTextField
                id="description"
                label={t('documents.document-description')}
                onChange={(event) => onChangeRow('description', event.target.value)}
                value={currentDocumentData?.description}
              />
            </Grid>
            <Grid item xs={3}>
              <MISAutocomplete
                label={t('documents.authoring-organization')}
                onChange={(value) => onChangeRow('authoringOrgId', value)}
                options={orgOptions}
                value={
                  orgOptions?.find((x) => x.value.id === currentDocumentData?.authoringOrgId)
                    ?.label || ''
                }
              />
            </Grid>
            <Grid item xs={3}>
              <MISTextField
                id="author"
                label={t('documents.author')}
                onChange={(event) => onChangeRow('author', event.target.value)}
                value={currentDocumentData?.author}
              />
            </Grid>
            <Grid item xs={2}>
              <MISDatePicker
                id="authoredDatetime"
                isDefaultToday
                label={t('documents.authored-on')}
                onChange={(value: ChangeEvent<HTMLInputElement>) => {
                  onChangeRow('authoredDatetime', value)
                }}
                value={
                  currentDocumentData?.authoredDatetime || editData?.document?.authoredDatetime
                }
              />
            </Grid>
            <Grid item xs={12}>
              <MISMultiValueAutocomplete
                allowFreeText
                label={t('documents.keywords')}
                onChange={(event: SyntheticEvent, newValue: MultiValueOption[]) => {
                  onChangeRow('keywords', newValue.map((v) => v.value).toString())
                }}
                options={tags?.map((v) => ({ label: v || '', value: v || '' })) || []}
                value={
                  currentDocumentData?.tags
                    ? currentDocumentData?.tags
                        .toString()
                        .split(',')
                        .map((v) => ({ label: v, value: v }))
                    : []
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <MISAutocomplete
                    label={t('documents.kind-of-document')}
                    onChange={(value) => onChangeRow('ontologyKind', value)}
                    options={getFormattedOptions(kindOfDocumentOptions)}
                    value={
                      kindOfDocumentOptions.find(
                        (o) => o.code === currentDocumentData?.ontologyKind?.code
                      )?.name || ''
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <MISAutocomplete
                    label={t('documents.type-of-service')}
                    onChange={(value) => onChangeRow('ontologyType', value)}
                    options={getFormattedOptions(typeOfServiceOptions)}
                    value={
                      typeOfServiceOptions.find(
                        (o) => o.code === currentDocumentData?.ontologyType?.code
                      )?.name || ''
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <MISAutocomplete
                    label={t('documents.subject-matter')}
                    onChange={(value) => onChangeRow('ontologySubject', value)}
                    options={getFormattedOptions(subjectMatterOptions)}
                    value={
                      subjectMatterOptions.find(
                        (o) => o.code === currentDocumentData?.ontologySubject?.code
                      )?.name || ''
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <MISAutocomplete
                    label={t('documents.setting')}
                    onChange={(value) => onChangeRow('ontologySetting', value)}
                    options={getFormattedOptions(settingOptions)}
                    value={
                      settingOptions.find(
                        (o) => o.code === currentDocumentData?.ontologySetting?.code
                      )?.name || ''
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <MISAutocomplete
                    label={t('documents.role')}
                    onChange={(value) => onChangeRow('ontologyRole', value)}
                    options={getFormattedOptions(roleOptions)}
                    value={
                      roleOptions.find((o) => o.code === currentDocumentData?.ontologyRole?.code)
                        ?.name || ''
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Typography sx={{ fontWeight: 'bold', mb: 2 }} variant="h6">
                {t('documents.ontology-title-select')}
              </Typography>
              <List
                sx={{
                  '& ul': { padding: 0 },
                  bgcolor: 'background.paper',
                  maxHeight: 300,
                  maxWidth: 360,
                  overflow: 'auto',
                  position: 'relative',
                  width: '100%',
                }}
              >
                {titleOptions?.map((option) => (
                  <ListItemButton
                    key={`item-${option.id}`}
                    onClick={() => onChangeRow('ontologyTitle', option)}
                  >
                    <ListItemText primary={`${option.name}`} />
                  </ListItemButton>
                ))}
              </List>
              <span>
                {t('documents.selected-title')}{' '}
                {
                  titleOptions.find((x) => currentDocumentData?.ontologyTitle?.code === x.code)
                    ?.name
                }
              </span>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <MISButton onClick={() => handleClose(false)}>{t('common.button.cancel')}</MISButton>
          <MISButton onClick={handleSaveClicked}>{t('common.button.save')}</MISButton>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddEditDocumentDialog
