import { MutableRefObject, useCallback, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import AddIcon from '@mui/icons-material/Add'
import { Button } from '@mui/material'
import DockLayout, { PanelData, TabData } from 'rc-dock'
import { store } from 'store/index'
import { setActiveHistoricTabs } from 'store/reducers/charting'
import { HistoricalItem } from './HistoricalItem'
import './HistoricalPanel.css'
import TEMPLATES from '../modules/TemplatesToolbar/Templates'

interface HistoricalPanelProps {
  dockLayoutRef: MutableRefObject<DockLayout | null>
}

const HistoricalPanel = ({ dockLayoutRef }: HistoricalPanelProps) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { t } = useTranslation('common')

  const HistoricalPanel: PanelData = {
    id: 'historicalPanel',
    panelLock: {
      minHeight: 50,
      minWidth: 200,
      panelExtra: (panelData) => (
        <Button onClick={() => addHistorical('')}>
          <AddIcon />
          Add Tab
        </Button>
      ),
    },
    size: 20,
    tabs: [],
  }

  const groups = {
    'card custom': {
      floatable: true,
      maximizable: false,
    },
  }

  const updateTabTitle = useCallback(
    (tabId, title) => {
      const historicalTab = dockLayoutRef?.current?.find(tabId) as TabData
      dockLayoutRef?.current?.updateTab(tabId, { ...historicalTab, title: title })
      const latestTabsData = store.getState().charting.activeHistoricTabs
      const tabIndex = latestTabsData.findIndex((x) => x.templateId === tabId)

      if (tabIndex !== -1) {
        const updatedTabsData = [...latestTabsData]
        updatedTabsData[tabIndex] = {
          ...updatedTabsData[tabIndex],
          templateName: title,
        }
        dispatch(setActiveHistoricTabs(updatedTabsData))
      }
    },
    [dispatch, dockLayoutRef]
  )

  const updateTabFilters = useCallback(
    (tabId, filters) => {
      const latestTabsData = store.getState().charting.activeHistoricTabs
      const tabIndex = latestTabsData.findIndex((x) => x.templateId === tabId)

      if (tabIndex !== -1) {
        const updatedTabsData = [...latestTabsData]
        updatedTabsData[tabIndex] = {
          ...updatedTabsData[tabIndex],
          filters: filters,
        }
        dispatch(setActiveHistoricTabs(updatedTabsData))
      }
    },
    [dispatch]
  )

  const addHistorical = useCallback(
    (templateName?: string, filters?: string[]) => {
      return new Promise<void>((resolve) => {
        const randomId = Math.random().toString(36).substring(7)
        const newHistorical = {
          cached: true,
          closable: true,
          content: (
            <HistoricalItem
              onSelectTemplate={(value) => updateTabTitle(randomId, value)}
              onSetFilters={(value) => updateTabFilters(randomId, value)}
              preFiltered={filters || []}
              preTemplateId={templateName ? templateName : undefined}
            />
          ),
          group: 'card custom',
          id: randomId,
          title: templateName ? templateName : `New Sheet`,
        }
        const historicalPanel = dockLayoutRef.current?.find('historicalPanel') as PanelData
        dockLayoutRef.current?.dockMove(newHistorical, historicalPanel, 'middle')
        const latestTabsData = store.getState().charting.activeHistoricTabs
        dispatch(
          setActiveHistoricTabs([
            ...latestTabsData,
            { templateId: randomId, templateName: templateName ? templateName : `New Sheet` },
          ])
        )

        requestAnimationFrame(() => {
          resolve()
        })
      })
    },
    [dispatch, dockLayoutRef, updateTabFilters, updateTabTitle]
  )

  const hasRun = useRef(false)

  const addStaticHistorical = useCallback(async () => {
    const staticTemplates = Object.values(TEMPLATES).filter((each) => each.historical === 'table')

    if (hasRun.current) return
    hasRun.current = true

    const promises: Promise<void>[] = []
    for (const template of staticTemplates) {
      const promise = addHistorical(t(TEMPLATES[template.name]?.key))
      promises.push(promise)
    }
    await Promise.all(promises)
  }, [addHistorical, t])

  useEffect(() => {
    const latestTabsData = store.getState().charting.activeHistoricTabs
    const isBlankCanvas =
      location.pathname.endsWith('/charting') && !location.pathname.includes('client-record')

    if ((!latestTabsData || latestTabsData.length === 0) && !isBlankCanvas) {
      addStaticHistorical()
    }
  }, [addStaticHistorical, location.pathname])

  return { addHistorical, groups: groups, panel: HistoricalPanel }
}

export default HistoricalPanel
