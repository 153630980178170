/* eslint-disable no-prototype-builtins */
import { ChangeEvent, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import 'quill-mention'
import { useParams } from 'react-router-dom'
import { Grid, Stack } from '@mui/material'
import { useRecoilValue } from 'recoil'
import MISAutocomplete from 'common/components/form/MISAutocomplete'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISDurationField from 'common/components/form/MISDurationField'
import { isoDateToDisplayFormat } from 'common/utils/DateUtils'
import {
  displayChipsUtil,
  evaluateChipsUtil,
  evaluateLabelUtil,
} from 'modules/shared/StaffAssociation/StaffAssociationUtils'
import { StateChip, StateSelector } from 'modules/shared/State'
import { StateValueType, StateWithInstructionsType } from 'modules/shared/State/StateSelector'
import { ErrorType, getError, hasError } from 'modules/shared/utils'
import { userIdState } from 'recoil/recentClients'
import { terminologySelector } from 'recoil/terminology'
import {
  ChartingEntryControllerService,
  ChartingEntryDTO,
  CodedRef,
  PersonnelDTO,
} from 'services/openapi'
import { MIS_ENCOUNTER_SERVICE_STATE, MIS_VOID_REASON_TYPE } from 'services/terminologyConstants'
import ChartingRevisionsPopover from './ChartingRevisionsPopover'

interface ChartingFieldsProps {
  chartingData?: ChartingEntryDTO
  errors: ErrorType[]
  onChangeChartingData: (chartingData: ChartingEntryDTO) => void
  onChangeState: (chartingData: ChartingEntryDTO) => void
  onSelectChartRevHistory: (chartingData: ChartingEntryDTO, isLatestVersion: boolean) => void
  providers: PersonnelDTO[]
}

const ChartingFields = ({
  chartingData,
  errors,
  onChangeChartingData,
  onChangeState,
  onSelectChartRevHistory,
  providers,
}: ChartingFieldsProps) => {
  const { t } = useTranslation('common')
  const { clientId } = useParams()
  const userId = useRecoilValue(userIdState)
  const stateOptions = useRecoilValue(terminologySelector(MIS_ENCOUNTER_SERVICE_STATE))
  const voidReasonOptions = useRecoilValue(terminologySelector(MIS_VOID_REASON_TYPE))

  const providerOptions = useMemo(
    () =>
      providers.map((provider: PersonnelDTO) => {
        return {
          ...provider,
          label: evaluateLabelUtil(provider?.names),
        }
      }),
    [providers]
  )

  const handleDefaultProvider = useCallback(() => {
    const defaultProvider = providerOptions.find((provider) => provider.userId === userId)
    if (defaultProvider) {
      onChangeChartingData({
        ...chartingData,
        primaryProvider: defaultProvider.id,
      })
      return defaultProvider
    }
    return ''
  }, [chartingData, onChangeChartingData, providerOptions, userId])

  const handleGetNextStateOptions = useCallback(async () => {
    const nextStateOptions: StateWithInstructionsType[] = []
    if (clientId && chartingData?.id) {
      const response = await ChartingEntryControllerService.getNextStatesByChartingId(
        clientId,
        chartingData?.id
      )
      response.content?.forEach((item) => {
        if (item.state) nextStateOptions.push(item)
      })
    }
    return nextStateOptions
  }, [clientId, chartingData])

  const handleChangeState = useCallback(
    (chartingEntryId: string, state: CodedRef, reason?: CodedRef, comment?: string) => {
      onChangeState({
        ...chartingData,
        status: state,
        statusComment: comment,
        statusReason: reason,
      })
    },
    [chartingData, onChangeState]
  )

  const healthConcernStateValues: StateValueType[] = useMemo(
    () =>
      stateOptions.map((opt) => {
        switch (opt.code) {
          case 'REVISED_DRAFT':
            return {
              commentRequired: true,
              stateValue: opt,
            }
          case 'VOIDED':
            return {
              reasonCodeRequired: true,
              reasonCodeTerminology: voidReasonOptions,
              stateValue: opt,
            }
          default:
            return { stateValue: opt }
        }
      }),
    [stateOptions, voidReasonOptions]
  )

  const handleClickChartVersion = useCallback(
    (chartingData: ChartingEntryDTO, isLatestVersion: boolean) => {
      onSelectChartRevHistory(chartingData, isLatestVersion)
    },
    [onSelectChartRevHistory]
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid alignItems="center" container justifyContent="center" spacing={1} sx={{ mb: 1 }}>
          <Grid item xs={2}>
            <MISDatePicker
              error={hasError(errors, 'charting-service-date-time')}
              helperText={getError(errors, 'charting-service-date-time')}
              inputProps={{
                value: chartingData?.serviceDateTime
                  ? isoDateToDisplayFormat(chartingData?.serviceDateTime.toString())
                  : '',
              }}
              isDefaultToday
              label={t('charting.canvas.date-of-service')}
              onChange={(value: ChangeEvent<HTMLInputElement>) =>
                onChangeChartingData({
                  ...chartingData,
                  serviceDateTime: value?.toString().substring(0, 19),
                })
              }
              required
              value={chartingData?.serviceDateTime?.toString()}
            />
          </Grid>
          <Grid item xs={1}>
            <MISDurationField
              label={t('charting.canvas.duration')}
              onChange={(value) =>
                onChangeChartingData({
                  ...chartingData,
                  duration: value as number,
                })
              }
              value={chartingData?.duration ? Number(chartingData?.duration) : ''}
            />
          </Grid>
          <Grid item xs={3}>
            {providerOptions.length > 0 && (
              <MISAutocomplete
                error={hasError(errors, 'charting-primary-provider')}
                helperText={getError(errors, 'charting-primary-provider')}
                label={t('charting.canvas.primary-provider')}
                onChange={(value) =>
                  onChangeChartingData({
                    ...chartingData,
                    primaryProvider: value?.id,
                  })
                }
                options={providerOptions}
                renderOption={(props, provider) => {
                  const label = evaluateLabelUtil(provider?.names)
                  const chips = evaluateChipsUtil(provider?.jobFunctions)
                  return (
                    <Stack
                      {...props}
                      direction="row"
                      key={label}
                      sx={{
                        '&.MuiAutocomplete-option': {
                          justifyContent: 'start',
                          width: '600px',
                          whiteSpace: 'nowrap',
                        },
                        cursor: 'pointer',
                        p: 1,
                      }}
                    >
                      <span>{label}</span>
                      <span>{displayChipsUtil(chips)}</span>
                    </Stack>
                  )
                }}
                required
                value={
                  chartingData?.primaryProvider
                    ? providerOptions.find(
                        (provider) => provider.id === chartingData?.primaryProvider
                      )
                    : handleDefaultProvider()
                }
              />
            )}
          </Grid>
          <Grid item xs={2}>
            <StateChip
              comment={chartingData?.statusComment}
              label={
                chartingData?.status
                  ? (stateOptions?.find(
                      (o: CodedRef) => o.code === (chartingData?.status as CodedRef)?.code
                    )?.name as string)
                  : 'Draft'
              }
              reason={
                voidReasonOptions.find((o) => o.code === chartingData?.statusReason?.code)?.name
              }
            />
          </Grid>
          <Grid item xs={1}>
            {chartingData?.status && (
              <StateSelector
                entityId={chartingData?.id || ''}
                onGetNextStates={handleGetNextStateOptions}
                onSelect={handleChangeState}
                stateValues={healthConcernStateValues}
                warningText={t('common.state-selector.warning')}
              />
            )}
          </Grid>
          <Grid item xs={3}>
            {chartingData && chartingData.id && (
              <ChartingRevisionsPopover
                chartingData={chartingData}
                onClickChartVersion={handleClickChartVersion}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}
export default ChartingFields
