import { useCallback } from 'react'
import { Box } from '@mui/material'
import AuthService from 'core/helpers/security/AuthService'
import { DocumentDetails } from 'modules/charting/Charting'
import { downloadFile } from 'modules/shared/utils'
import FileItem from './FileItem'

export type DocumentListProps = {
  files: DocumentDetails[]
  onDelete: (id: string) => void
}

const DocumentList = ({ files, onDelete }: DocumentListProps) => {
  const handleDownload = useCallback(async (file: DocumentDetails) => {
    const token = AuthService.getAccessToken()
    downloadFile(file.fileId, token, file.name)
  }, [])

  return (
    <Box
      sx={{
        border: '1px solid #cbcbcb',
        borderRadius: 2,
        display: 'flex',
        flexWrap: 'wrap',
        gap: 1,
        height: '56px',
        overflow: 'auto',
        padding: 0.5,
      }}
    >
      {files.map((file) => (
        <FileItem
          documentDetails={file}
          key={file.name}
          onDelete={() => {
            onDelete(file.name)
          }}
          onDownload={() => {
            handleDownload(file)
          }}
        />
      ))}
    </Box>
  )
}

export default DocumentList
