import { SyntheticEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'
import { useRecoilState, useRecoilValue } from 'recoil'
import MISDatePicker from 'common/components/form/MISDatePicker'
import MISMultiValueAutocomplete, {
  MultiValueOption,
} from 'common/components/form/MISMultiValueAutocomplete'
import MISSelectDropdown from 'common/components/form/MISSelectDropdown'
import { useSnack } from 'common/components/snackbar/useSnack'
import { isoDateToLocalDisplayFormat } from 'common/utils/DateUtils'
import { useErrorHandler } from 'core/components/errorhandler/ErrorHandler'
import Languages from 'modules/shared/Languages/Languages'
import SectionHeader from 'modules/shared/SectionHeader/SectionHeader'
import { ErrorType, getError, getFormattedOptions } from 'modules/shared/utils'
import { isDirtyState } from 'recoil/isDirty'
import {
  expertiseAtom,
  personnelDetailsState,
  personnelIdSelector,
  personnelLanguagesSelector,
} from 'recoil/personnelDetails'
import { terminologySelector } from 'recoil/terminology'
import {
  PersonnelControllerService,
  PersonnelDTO,
  PersonnelLanguageControllerService,
  PersonnelLanguageDTO,
  PersonnelLightDTO,
} from 'services/openapi'
import {
  MIS_GENDER_VOCAB_NAME,
  MIS_MEMBER_PRONOUNS_VOCAB_NAME,
} from 'services/terminologyConstants'

const Profile = () => {
  const { t } = useTranslation('common')
  const { showSnackSuccess } = useSnack()
  const { handleApiError } = useErrorHandler()
  const [isDirty, setIsDirty] = useRecoilState(isDirtyState)
  const personnelId = useRecoilValue(personnelIdSelector)
  const [personnelData, setPersonnelData] = useRecoilState(personnelDetailsState)
  const [expertiseValues, setExpertiseValues] = useRecoilState(expertiseAtom)
  const [languages, setLanguages] = useRecoilState(personnelLanguagesSelector)
  const genderOptions = useRecoilValue(terminologySelector(MIS_GENDER_VOCAB_NAME))
  const pronounsOptions = useRecoilValue(terminologySelector(MIS_MEMBER_PRONOUNS_VOCAB_NAME))
  const [currentPersonnel, setCurrentPersonnel] = useState<PersonnelDTO | undefined>(personnelData)
  const [errors, setErrors] = useState<ErrorType[]>([])
  const [save, setSave] = useState(false)

  const handleChange = useCallback(
    (value: any, field: string) => {
      if (!isDirty) setIsDirty(true)

      const updatedPersonnel = {
        ...currentPersonnel,
        [field]: value || null,
      }
      setCurrentPersonnel(updatedPersonnel)
    },
    [currentPersonnel, isDirty, setIsDirty]
  )

  const validate = useCallback(() => {
    let isValid = true
    const errors: ErrorType[] = []

    if (!currentPersonnel?.gender?.code) {
      isValid = false
      errors.push({
        field: 'personnel-gender',
        message: t('provider-staff.validation.gender.required'),
      })
    }

    return {
      errors,
      isValid,
    }
  }, [currentPersonnel, t])

  const onPersonnelSave = useCallback(() => {
    setIsDirty(false)

    const savePeronnelProfile = async () => {
      await PersonnelControllerService.updatePersonnel(
        personnelId || '',
        currentPersonnel as PersonnelLightDTO
      )
        .then((response: PersonnelDTO) => {
          setPersonnelData({
            ...personnelData,
            dateOfBirth: response.dateOfBirth || undefined,
            dateOfBirthEstimated: response.dateOfBirthEstimated || undefined,
            expertise: response.expertise || undefined,
            gender: response.gender || undefined,
            pronoun: response.pronoun || undefined,
          })
          if (response?.expertise) {
            setExpertiseValues([
              ...new Set(expertiseValues?.concat(response?.expertise?.split(','))),
            ])
          }
          if (!languages || (languages && languages.length === 0)) {
            showSnackSuccess(t('api.save-success'))
          }
        })
        .catch((error) => {
          handleApiError(error)
        })
    }

    if (personnelId) {
      const { errors, isValid } = validate()
      if (isValid) {
        setSave(true)
        savePeronnelProfile()
      } else {
        setErrors(errors)
      }
    }
  }, [
    personnelId,
    personnelData,
    currentPersonnel,
    setPersonnelData,
    languages,
    expertiseValues,
    setExpertiseValues,
    validate,
    showSnackSuccess,
    handleApiError,
    setIsDirty,
    t,
  ])

  const onSaveLanguages = useCallback(
    (languages) => {
      const saveLanguages = async (personnelId: string, languages: PersonnelLanguageDTO[]) => {
        await PersonnelLanguageControllerService.savePersonnelLanguages(personnelId, languages)
          .then((response: PersonnelLanguageDTO[]) => {
            if (response) {
              response.sort((a, b) => (a?.isPrimary ? -1 : b?.isPrimary ? 1 : 0))
              setLanguages(response)
              showSnackSuccess(t('api.save-success'))
            }
          })
          .catch((error) => handleApiError(error))
      }

      setSave(false)
      if (personnelId) {
        saveLanguages(personnelId, languages)
      }
    },
    [handleApiError, personnelId, setLanguages, showSnackSuccess, t]
  )

  const onDeleteLanguage = useCallback(
    (languageId: string | undefined) => {
      const deleteLanguage = async (personnelId: string, languageId: string) => {
        await PersonnelLanguageControllerService.deletePersonnelLanguage(personnelId, languageId)
          .then(() => {
            setLanguages(languages?.filter((language) => language.id !== languageId))
            showSnackSuccess(t('api.save-success'))
          })
          .catch((error) => handleApiError(error))
      }
      if (personnelId && languageId) deleteLanguage(personnelId, languageId)
    },
    [handleApiError, languages, personnelId, setLanguages, showSnackSuccess, t]
  )

  const toggleSave = useCallback((save: boolean) => setSave(save), [])

  useEffect(() => setCurrentPersonnel(personnelData), [personnelData])

  return (
    <>
      <SectionHeader
        onSave={() => onPersonnelSave()}
        saveLabel={t('common.button.save')}
        title={t('client-person.labels.title')}
      />
      <Box className="provider-staff-section-container">
        {currentPersonnel && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {genderOptions && (
                <MISSelectDropdown
                  error={!!getError(errors, 'personnel-gender')}
                  helperText={getError(errors, 'personnel-gender')}
                  id="gender"
                  label={t('client-person.gender')}
                  onChange={(event) => handleChange(event.target.value, 'gender')}
                  options={getFormattedOptions(genderOptions)}
                  required
                  value={genderOptions.find((o) => o.code === currentPersonnel?.gender?.code)}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              {pronounsOptions && (
                <MISSelectDropdown
                  id="pronouns"
                  label={t('client-person.pronouns')}
                  onChange={(event) => handleChange(event.target.value, 'pronoun')}
                  options={getFormattedOptions(pronounsOptions)}
                  value={pronounsOptions.find((o) => o.code === currentPersonnel?.pronoun?.code)}
                />
              )}
            </Grid>
            <Grid item xs={4}>
              <MISDatePicker
                disableFuture
                displayAge
                displayEstimated
                estimated={currentPersonnel?.dateOfBirthEstimated}
                label={t('client-person.birth-date')}
                onChange={(value) =>
                  handleChange(isoDateToLocalDisplayFormat(value), 'dateOfBirth')
                }
                onChangeEstimated={() =>
                  handleChange(!currentPersonnel.dateOfBirthEstimated, 'dateOfBirthEstimated')
                }
                value={currentPersonnel?.dateOfBirth || ''}
              />
            </Grid>
            <Grid item xs={12}>
              <MISMultiValueAutocomplete
                allowFreeText
                label="Expertise"
                onChange={(event: SyntheticEvent, newValue: MultiValueOption[]) => {
                  handleChange(newValue.map((value) => value.value).toString(), 'expertise')
                }}
                options={expertiseValues?.map((v) => ({ label: v || '', value: v || '' })) || []}
                value={
                  currentPersonnel?.expertise
                    ? currentPersonnel?.expertise.split(',').map((v) => ({ label: v, value: v }))
                    : []
                }
              />
            </Grid>
          </Grid>
        )}
        <Languages
          languages={languages ? (languages as PersonnelLanguageDTO[]) : []}
          onDelete={onDeleteLanguage}
          onSave={onSaveLanguages}
          save={save}
          toggleSave={toggleSave}
        />
      </Box>
    </>
  )
}

export default Profile
