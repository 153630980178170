import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { LocalizationProvider } from '@mui/lab'
import AdapterLuxon from '@mui/lab/AdapterLuxon'
import { useRecoilState } from 'recoil'
import MISBaseContainer from 'common/components/form/MISBaseContainer'
import { TTemplateBlot } from 'modules/charting/components/blots/TemplateBlot'
import useProviders from 'modules/shared/hooks/useProviders'
import { isTemplateUpdatedState } from 'recoil/charting'
import { CancelablePromise, ChartingEntryControllerService } from 'services/openapi'
import { selectUserId } from 'store/selectors/user'
import UserDashboardEntry from './UserDashboardEntry'
import UserDashboardTable from './UserDashboardTable'
import { isDateBeforeOrEqualDate } from '../../../common/utils/DateUtils'

export type TDashboardEntry = {
  id: string
  clientId?: string
  description?: string
  dueDate?: string
  priority?: string
  state?: string
  template: TTemplateBlot
  templateDisplayName?: string
}

const UserDashboard = () => {
  const providers = useProviders()
  const userId = useSelector(selectUserId)
  const { t } = useTranslation('common')
  const [dashboardEntry, setDashboardEntry] = useState<TDashboardEntry>()
  const [dashboardEntries, setDashboardEntries] = useState<TDashboardEntry[]>([])
  const [isTemplateUpdated, setIsTemplateUpdated] = useRecoilState(isTemplateUpdatedState)

  const provider = useMemo(
    () => providers?.find((provider) => provider.userId === userId),
    [providers, userId]
  )

  const handleSelectEntry = useCallback(
    (id: string) =>
      setDashboardEntry(dashboardEntries?.find((each) => each.template.templateRecordId === id)),
    [dashboardEntries]
  )

  useEffect(() => {
    const getTemplateData = async (providerId: string) => {
      const promises: CancelablePromise<any>[] = []
      promises.push(
        ChartingEntryControllerService.getFilteredChartingEntries1(
          'FollowUpTemplate',
          undefined,
          [`assignedTo.id|eq|${providerId}`],
          true
        )
      )

      // backend doesn't support 'Or' paramerter for filters,
      // need to update to one request with 'Or' parameter after updating the backend
      promises.push(
        ChartingEntryControllerService.getFilteredChartingEntries1(
          'ReferralTemplate',
          undefined,
          [`referredTo|eq|${providerId}`],
          true
        )
      )
      promises.push(
        ChartingEntryControllerService.getFilteredChartingEntries1(
          'ReferralTemplate',
          undefined,
          [`referredBy|eq|${providerId}`],
          true
        )
      )
      promises.push(
        ChartingEntryControllerService.getFilteredChartingEntries1(
          'ReferralTemplate',
          undefined,
          [`sentBy|eq|${providerId}`],
          true
        )
      )
      promises.push(
        ChartingEntryControllerService.getFilteredChartingEntries1(
          'ReferralTemplate',
          undefined,
          [`createdBy|eq|${providerId}`],
          true
        )
      )

      const chartingEntriesResp = await Promise.all(promises)

      if (chartingEntriesResp?.length) {
        const dashboardEntries: TDashboardEntry[] = chartingEntriesResp
          .flatMap((entryList) => {
            return entryList.flatMap((entry) => {
              return entry.chartingTemplateBlot
                .map((template) => {
                  const dashboardEntry: TDashboardEntry = {
                    clientId: entry.clientId?.length > 0 ? entry.clientId[0] : undefined,
                    id: entry.id,
                    state: template.templateData.state,
                    template: template,
                  }

                  if (template.templateId === 'FollowUpTemplate') {
                    return {
                      ...dashboardEntry,
                      description: template.templateData.description,
                      dueDate: template.templateData.dueDate,
                      priority: template.templateData.priority?.code,
                      templateDisplayName: t('user-dashboard.table.row.follow-up'),
                    }
                  }
                  if (template.templateId === 'ReferralTemplate') {
                    return {
                      ...dashboardEntry,
                      description: template.templateData.reason,
                      dueDate: template.templateData.appointmentDate,
                      templateDisplayName: t('user-dashboard.table.row.referral'),
                    }
                  }
                  return null
                })
                .filter((item) => item !== null)
            })
          })
          .filter(
            // filter duplicates
            (entry, index, array) =>
              array.findIndex(
                (val) => val.template?.templateRecordId === entry?.template?.templateRecordId
              ) === index
          )

        const entriesWithDueDate = dashboardEntries.filter((each) => each.dueDate)
        entriesWithDueDate.sort((a, b) =>
          isDateBeforeOrEqualDate(a.dueDate as string, b.dueDate as string) ? -1 : 1
        )
        const entriesWithoutDueDate = dashboardEntries.filter((each) => !each.dueDate)
        const sortedDashboardEntries = [...entriesWithDueDate, ...entriesWithoutDueDate]
        setDashboardEntries(sortedDashboardEntries)
      } else setDashboardEntries([])
    }

    if (provider?.id) {
      getTemplateData(provider.id)
      setIsTemplateUpdated(false)
    }
  }, [provider, isTemplateUpdated, setIsTemplateUpdated, t])

  return (
    <MISBaseContainer>
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <UserDashboardTable entries={dashboardEntries} onSelect={handleSelectEntry} />
      </LocalizationProvider>
      {dashboardEntry && (
        <UserDashboardEntry
          clientId={dashboardEntry.clientId}
          entry={dashboardEntry.template}
          id={dashboardEntry.id}
          onClose={() => setDashboardEntry(undefined)}
        />
      )}
    </MISBaseContainer>
  )
}

export default UserDashboard
