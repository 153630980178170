import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'
import { Box, ListItem, ListItemIcon, ListItemText, MenuItem, Popover } from '@mui/material'
import { useRecoilState } from 'recoil'
import MISClientSearchIcon from 'common/components/icons/MISClientSearchIcon'
import GLOBAL from 'common/styles/global.scss'
import { MISNavigationState } from 'core/components/navigation/MISNavigationState'

interface SearchMenuType {
  id: number
  key: string
  label: string
  navItem: string
  route: string
}

const SEARCH_MENU_CONFIG: SearchMenuType[] = [
  {
    id: 1,
    key: 'search-clients',
    label: 'navigation.left-nav-menu.search.clients',
    navItem: 'navigation.left-nav-menu.client-search',
    route: '/clients',
  },
  {
    id: 2,
    key: 'search-providers',
    label: 'navigation.left-nav-menu.search.providers',
    navItem: 'navigation.left-nav-menu.admin.providers-staff',
    route: '/provider-staff-record/search',
  },
  {
    id: 3,
    key: 'search-groups',
    label: 'navigation.left-nav-menu.search.groups',
    navItem: 'navigation.left-nav-menu.admin.groups',
    route: '/admin/groups',
  },
]

interface SearchSelectorProps {
  onClickMenuItem: (index: number) => void
}

const SearchSelector = ({ onClickMenuItem }: SearchSelectorProps) => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const [seletedMenuItem, setSeletedMenuItem] = useRecoilState(MISNavigationState)

  const handleClose = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(null)
  }, [])

  const handleSearchClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
  }, [])

  const handleSearchItemClick = useCallback(
    (key: string, navItemName: string, path: string) => {
      setAnchorEl(null)
      if (key !== 'search-clients') {
        onClickMenuItem(1)
      }
      setSeletedMenuItem(navItemName)
      navigate(path)
    },
    [navigate, onClickMenuItem, setSeletedMenuItem]
  )

  return (
    <>
      <ListItem
        component={Link}
        onClick={handleSearchClick}
        // rel={undefined}
        sx={{
          ...(seletedMenuItem === 'navigation.left-nav-menu.client-search' && {
            backgroundColor: GLOBAL.INNER_LEFT_SIDEBAR_BACKGROUND,
          }),
          color: GLOBAL.BLACK,
          flexDirection: 'column',
        }}
        // target={undefined}
        to="#"
      >
        <ListItemIcon sx={{ minWidth: 'auto' }}>
          <MISClientSearchIcon />
        </ListItemIcon>
        <ListItemText
          primary={t('navigation.left-nav-menu.search.search')}
          primaryTypographyProps={{ fontSize: GLOBAL.FONT_BODY_REGULAR }}
        />
      </ListItem>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        id="menu-button-popover"
        onClick={(event) => event.stopPropagation()}
        onClose={handleClose}
        open={Boolean(anchorEl)}
      >
        <Box>
          {SEARCH_MENU_CONFIG.map(({ id, key, label, navItem, route }) => (
            <MenuItem
              divider
              key={id}
              onClick={() => handleSearchItemClick(key, navItem, route)}
              sx={{ height: 54, minWidth: 300 }}
            >
              {t(label)}
            </MenuItem>
          ))}
        </Box>
      </Popover>
    </>
  )
}

export default SearchSelector
